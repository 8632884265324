import React, {useState, useEffect} from "react";
import axios from 'axios';
import { useMyRegistration } from "../../contexts/myregistration.js";
import { useEventSettings } from "../../contexts/eventsettings";


import { FormCard, FormLabel, SaveButton, SaveSpinnerContainer } from "../../styled-components/Forms.js";

import Switch from '../../custom-widgets/Switch.js'; 
import Checkbox from '../../custom-widgets/Checkbox.js'; 

import Form from 'react-bootstrap/Form'
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Spinner from 'react-bootstrap/Spinner'

import '../../custom-widgets/Switch.css'; 


function PrivateInfoEditor(props) {
	const { myRegistration, setMyRegistration } = useMyRegistration(); 
  const { eventSettings } = useEventSettings();
	const [isSavingToServer, setIsSavingToServer] = useState(false);
	const [isEdited, setIsEdited] = useState(false);
	const [genderOptions, setGenderOptions] = useState({});
	const [tshirtsizeOptions, setTshirtsizeOptions] = useState({});
	const [dietaryOptions, setDietaryOptions] = useState({});
	
	const [firstName, setFirstName] = useState('Loading...');
	const [lastName, setLastName] = useState('Loading...');
	const [email, setEmail] = useState('Loading...');
	const [phone, setPhone] = useState('Loading...');
	const [gender, setGender] = useState('');
	const [birthdayMonth, setBirthdayMonth] = useState('');
	const [birthdayDate, setBirthdayDate] = useState('');
	const [birthdayYear, setBirthdayYear] = useState('');
	const [tShirtSize, setTShirtSize] = useState('');
	const [smsOk, setSmsOk] = useState(true);
	const [erName, setErName] = useState('Loading...');
	const [erPhone, setErPhone] = useState('Loading...');
	const [foodAllergies, setFoodAllergies] = useState('Loading...');
	const [erAttending, setErAttending] = useState(false);
	const [reducedMobility, setReducedMobility] = useState(false);
	const [adaRequired, setAdaRequired] = useState(false);
	const [medicalAllergies, setMedicalAllergies] = useState('Loading...');
	const [emergencyCareInfo, setEmergencyCareInfo] = useState('Loading...');
	const [careNotes, setCareNotes] = useState('Loading...');
	const [dietaryCeliac, setDietaryCeliac] = useState(false);
	const [dietaryGlutenFree, setDietaryGlutenFree] = useState(false);
	const [dietaryVegetarian, setDietaryVegetarian] = useState(false);
	const [dietaryVegan, setDietaryVegan] = useState(false);
	const [dietaryLactose, setDietaryLactose] = useState(false);
	const [dietaryNuts, setDietaryNuts] = useState(false);
	const [dietaryLowCarb, setDietaryLowCarb] = useState(false);
	
	useEffect(() => {
	axios.get(process.env.REACT_APP_API_HOST+"/events/"+eventSettings.key+"/fieldoptions")
				.then(result => {
								if (result.status === 200) {
									setGenderOptions(result.data["guest.gender"]); 
									setDietaryOptions(result.data["care.dietary"]); 
									setTshirtsizeOptions(result.data["guest.tshirtsize"]); 
								} else {
								}
							}).catch(e => {
							});
	},[eventSettings.key]); 
	
	var today = new Date(); 
	
	const birthdayDayOptions = []; 
	const birthdayYearOptions = []; 
	for(var d=1; d<32; d++){ 
		birthdayDayOptions.push(<option value={((d<10)?'0':'')+d} key={d}>{d}</option>); 
	} 
	for(var i=(parseInt(today.getFullYear())-17); i>1940; i--){ 
		birthdayYearOptions.push(<option value={i} key={i}>{i}</option>); 
	} 
	
	function handleFieldChange(id,val){ 
		setIsEdited(true); 
		if(id==='firstName') setFirstName(val); 
		if(id==='lastName') setLastName(val); 
		if(id==='email') setEmail(val); 
		if(id==='phone') setPhone(val); 
		if(id==='gender') setGender(val); 
		if(id==='er_name') setErName(val); 
		if(id==='er_phone') setErPhone(val); 
		if(id==='tshirtsize') setTShirtSize(val); 
		if(id==='foodAllergies') setFoodAllergies(val); 
		if(id==='medicalAllergies') setMedicalAllergies(val); 
		if(id==='emergencyCareInfo') setEmergencyCareInfo(val); 
		if(id==='careNotes') setCareNotes(val); 
		if(id==='birthday_month') setBirthdayMonth(val); 
		if(id==='birthday_day') setBirthdayDate(val); 
		if(id==='birthday_year') setBirthdayYear(val); 
	} 
	
	function savePublicProfile(){ 
		if(firstName!==null) myRegistration.guest.firstName = firstName; 
		if(lastName!==null) myRegistration.guest.website = lastName; 
		if(email!==null) myRegistration.guest.website = email; 
		myRegistration.guest.birthdate = birthdayYear+'-'+birthdayMonth+'-'+birthdayDate; 
		if(phone!==null) myRegistration.guest.phone.number = phone; 
 		myRegistration.guest.phone.smsOK = smsOk; 
		if(gender!==null) myRegistration.guest.gender = gender; 
 		myRegistration.guest.tShirtSize = tShirtSize; 
 		myRegistration.careAndFeeding.emergencyContact.isAttendingEvent = erAttending; 
		if(erName!==null) myRegistration.careAndFeeding.emergencyContact.name = erName; 
		if(erPhone!==null) myRegistration.careAndFeeding.emergencyContact.phone = erPhone; 
 		myRegistration.careAndFeeding.reducedMobility = reducedMobility; 
 		myRegistration.careAndFeeding.requiresADA = adaRequired; 
		if(foodAllergies!==null) myRegistration.careAndFeeding.foodAllergies = foodAllergies; 
		if(medicalAllergies!==null) myRegistration.careAndFeeding.medicalAllergies = medicalAllergies; 
		if(emergencyCareInfo!==null) myRegistration.careAndFeeding.emergencyMedicalInfo = emergencyCareInfo; 
		if(careNotes!==null) myRegistration.careAndFeeding.notes = careNotes; 
		
		myRegistration.careAndFeeding.dietaryConcerns = [];
		if(dietaryCeliac===true) myRegistration.careAndFeeding.dietaryConcerns.push('celiac');
		if(dietaryGlutenFree===true) myRegistration.careAndFeeding.dietaryConcerns.push('glutenfree');
		if(dietaryVegetarian===true) myRegistration.careAndFeeding.dietaryConcerns.push('vegetarian');
		if(dietaryVegan===true) myRegistration.careAndFeeding.dietaryConcerns.push('vegan');
		if(dietaryLactose===true) myRegistration.careAndFeeding.dietaryConcerns.push('lactose');
		if(dietaryNuts===true) myRegistration.careAndFeeding.dietaryConcerns.push('nuts');
		if(dietaryLowCarb===true) myRegistration.careAndFeeding.dietaryConcerns.push('lowcarb');
		
		setIsSavingToServer(true); 
		setMyRegistration(myRegistration); 
	} 
  useEffect(() => {
		if(myRegistration.guest)
			setFirstName((myRegistration.guest.firstName==null)?'':myRegistration.guest.firstName); 
		if(myRegistration.guest)
			setLastName((myRegistration.guest.lastName==null)?'':myRegistration.guest.lastName); 
		if(myRegistration.guest)
			setEmail((myRegistration.guest.email==null)?'':myRegistration.guest.email); 
		if(myRegistration.guest && myRegistration.guest.phone)
			setPhone((myRegistration.guest.phone.number==null)?'':myRegistration.guest.phone.number); 
		if(myRegistration.guest && myRegistration.guest.phone)
			setSmsOk(myRegistration.guest.phone.smsOK); 
		if(myRegistration.guest)
			setGender((myRegistration.guest.gender==null)?'':myRegistration.guest.gender); 
		if(myRegistration.guest)
			setTShirtSize((myRegistration.guest.tShirtSize==null)?'':myRegistration.guest.tShirtSize); 
		if(myRegistration.careAndFeeding && myRegistration.careAndFeeding.emergencyContact){
			setErName((myRegistration.careAndFeeding.emergencyContact.name==null)?'':myRegistration.careAndFeeding.emergencyContact.name); 
			setErPhone((myRegistration.careAndFeeding.emergencyContact.phone==null)?'':myRegistration.careAndFeeding.emergencyContact.phone); 
			setErAttending(myRegistration.careAndFeeding.emergencyContact.isAttendingEvent); 
		}
		if(myRegistration.guest && myRegistration.guest.birthdate){ 
			setBirthdayYear(myRegistration.guest.birthdate.split('-')[0]); 
			setBirthdayMonth(myRegistration.guest.birthdate.split('-')[1]); 
			setBirthdayDate(myRegistration.guest.birthdate.split('-')[2]); 
		} 
		
		if(myRegistration.careAndFeeding){
			setAdaRequired(myRegistration.careAndFeeding.requriesADA); 
			setReducedMobility(myRegistration.careAndFeeding.reducedMobility); 
			setFoodAllergies((myRegistration.careAndFeeding.foodAllergies==null)?'':myRegistration.careAndFeeding.foodAllergies); 
			setMedicalAllergies((myRegistration.careAndFeeding.medicalAllergies==null)?'':myRegistration.careAndFeeding.medicalAllergies); 
			setEmergencyCareInfo((myRegistration.careAndFeeding.emergencyMedicalInfo==null)?'':myRegistration.careAndFeeding.emergencyMedicalInfo); 
			setCareNotes((myRegistration.careAndFeeding.notes==null)?'':myRegistration.careAndFeeding.notes); 
		}
		if(myRegistration.careAndFeeding && myRegistration.careAndFeeding.dietaryConcerns){
			if(myRegistration.careAndFeeding.dietaryConcerns.indexOf('celiac')>=0){ setDietaryCeliac(true); }
			if(myRegistration.careAndFeeding.dietaryConcerns.indexOf('glutenfree')>=0){ setDietaryGlutenFree(true); }
			if(myRegistration.careAndFeeding.dietaryConcerns.indexOf('vegetarian')>=0){ setDietaryVegetarian(true); }
			if(myRegistration.careAndFeeding.dietaryConcerns.indexOf('vegan')>=0){ setDietaryVegan(true); }
			if(myRegistration.careAndFeeding.dietaryConcerns.indexOf('lactose')>=0){ setDietaryLactose(true); }
			if(myRegistration.careAndFeeding.dietaryConcerns.indexOf('nuts')>=0){ setDietaryNuts(true); }
			if(myRegistration.careAndFeeding.dietaryConcerns.indexOf('lowcarb')>=0){ setDietaryLowCarb(true); }
		} 
		if(myRegistration.status==='ok'){ setIsSavingToServer(false); setIsEdited(false); }
  }, [myRegistration]) 
			 
    return (<>
			<Container className="form-cards">
				<Row>
					<Col md={12} lg={6}>
						<FormCard id="" className="blue">
							<Card.Header as="h5">Legal Info & Contact Info</Card.Header>
							<Card.Body>
								<Form.Row>
									<FormLabel.Primary as={Col} sm={12}>Name (As It Appears On Your ID)</FormLabel.Primary>
								</Form.Row>
								<Form.Row>
									<Form.Group as={Col}  sm={6}>
										<FormLabel.Secondary>First Name</FormLabel.Secondary>
										<Form.Control id="firstname" value={firstName} onChange={e => { handleFieldChange(e.target.id, e.target.value); }}/>
									</Form.Group>
									<Form.Group as={Col} sm={6}>
										<FormLabel.Secondary>Last Name</FormLabel.Secondary>
										<Form.Control id="lastname" value={lastName} onChange={e => { handleFieldChange(e.target.id, e.target.value); }}/>
									</Form.Group>
								</Form.Row>
								<Form.Row>
									<FormLabel.Primary as={Col} sm={12}>Birthdate</FormLabel.Primary>
								</Form.Row>
								<Form.Row>
									<Form.Group as={Col}  sm={5}>
										<FormLabel.Secondary>Month</FormLabel.Secondary>
										<Form.Control id="birthday_month" as="select" value={birthdayMonth} onChange={e => { handleFieldChange(e.target.id, e.target.value); }} >
											<option></option>
											<option value="01">January</option>
											<option value="02">February</option>
											<option value="03">March</option>
											<option value="04">April</option>
											<option value="05">May</option>
											<option value="06">June</option>
											<option value="07">July</option>
											<option value="08">August</option>
											<option value="09">September</option>
											<option value="10">October</option>
											<option value="11">November</option>
											<option value="12">December</option>
										</Form.Control>
									</Form.Group>
									<Form.Group as={Col} sm={3}>
										<FormLabel.Secondary>Day</FormLabel.Secondary>
										<Form.Control id="birthday_day" as="select" value={birthdayDate} onChange={e => { handleFieldChange(e.target.id, e.target.value); }} >
											<option></option>
											{birthdayDayOptions}
										</Form.Control>									
									</Form.Group>
									<Form.Group as={Col} sm={4}>
										<FormLabel.Secondary>Year</FormLabel.Secondary>
										<Form.Control id="birthday_year" as="select" value={birthdayYear} onChange={e => { handleFieldChange(e.target.id, e.target.value); }} >
											<option></option>
											{birthdayYearOptions}
										</Form.Control>
									</Form.Group>
								</Form.Row>
								<Form.Row>
									<Form.Group as={Col} sm={12}>
										<FormLabel.Primary>Email Address</FormLabel.Primary>
										<Form.Control type="email" placeholder="" id="email" value={email} onChange={e => { handleFieldChange(e.target.id, e.target.value); }}/>
									</Form.Group>
								</Form.Row>
								<Form.Row>
									<Form.Group as={Col} sm={12}>
										<FormLabel.Primary>Mobile Phone Number</FormLabel.Primary>
										<Form.Control placeholder="" id="phone" value={phone} onChange={e => { handleFieldChange(e.target.id, e.target.value); }}/>
									</Form.Group>
								</Form.Row>
								
								<Form.Group as={Row} className="switch-row">
									<Switch isOn={smsOk} onColor="#0d9948" handleToggle={() => {setSmsOk(!smsOk); setIsEdited(true);  }} id="smsOk" />
									<Form.Label htmlFor="smsOk">
										Text messages are okay
										<Form.Text id="allowmessagesHelpBlock" muted>This lets us notify you of important activity, reminders, and updates.</Form.Text>
									</Form.Label>
								</Form.Group>
								<Form.Row>
									<Form.Group as={Col} sm={12}>
										<FormLabel.Primary>Gender Identity</FormLabel.Primary>
										<Form.Control id="gender" as="select" value={gender} onChange={e => { handleFieldChange(e.target.id, e.target.value); }} >
											<option></option>
											{
												Object.keys(genderOptions).map((value) => <option key={value} value={value}>{genderOptions[value]}</option> )
											}
										</Form.Control>
									</Form.Group>
								</Form.Row>
							</Card.Body>
						</FormCard>
																											 
						<FormCard id="" className="red">
							<Card.Header as="h5">Emergency Contact</Card.Header>
							<Card.Body>
								<Form.Row>
									<Form.Group as={Col}  sm={6}>
										<FormLabel.Secondary>Name</FormLabel.Secondary>
										<Form.Control id="er_name" value={erName} onChange={e => { handleFieldChange(e.target.id, e.target.value); }}/>
									</Form.Group>
									<Form.Group as={Col} sm={6}>
										<FormLabel.Secondary>Phone Number</FormLabel.Secondary>
										<Form.Control id="er_phone" value={erPhone} onChange={e => { handleFieldChange(e.target.id, e.target.value); }}/>
									</Form.Group>
								</Form.Row>
									<Form.Group as={Row} className="switch-row">
										<Switch isOn={erAttending} onColor="#0d9948" handleToggle={() => {setErAttending(!erAttending); setIsEdited(true);  }} id="er_attending" />
										<Form.Label htmlFor="er_attending">
											My emergency contact is attending PITM
										</Form.Label>
									</Form.Group>

							</Card.Body>
						</FormCard>


					</Col>


					<Col md={12} lg={6}>
																											 
						<FormCard id="" className="green">
							<Card.Header as="h5">Care and Feeding</Card.Header>
							<Card.Body>
								<Container>
								<Form.Row>
									<Form.Group as={Col} sm={12}>
										<FormLabel.Primary>T-Shirt Size</FormLabel.Primary>
										<Form.Control id="tshirtsize" as="select" value={tShirtSize} onChange={e => { handleFieldChange(e.target.id, e.target.value); }} >
											<option></option>
											{
												Object.keys(tshirtsizeOptions).map((value) => <option key={value} value={value}>{tshirtsizeOptions[value]}</option> )
											}
										</Form.Control>
									</Form.Group>
								</Form.Row>
								<Form.Row>
									<FormLabel.Primary as={Col} sm={12}>Dietary Restrictions</FormLabel.Primary>
								</Form.Row>
								<Form.Row>
									<Form.Group as={Col}> 
										<Checkbox checked={dietaryCeliac} label={dietaryOptions.celiac} id="dietary_celiac" key="celiac" onCheck={()=>{ setDietaryCeliac(!dietaryCeliac); setIsEdited(true); }}/> 
										<Checkbox checked={dietaryGlutenFree} label={dietaryOptions.glutenfree} id="dietary_glutenfree" key="gf"  onCheck={()=>{ setDietaryGlutenFree(!dietaryGlutenFree); setIsEdited(true) }}/> 
										<Checkbox checked={dietaryVegetarian} label={dietaryOptions.vegetarian} id="dietary_vegetarian" key="vegetarian" onCheck={()=>{ setDietaryVegetarian(!dietaryVegetarian); setIsEdited(true) }}/> 
										<Checkbox checked={dietaryVegan} label={dietaryOptions.vegan} id="dietary_vegan" key="vegan" onCheck={()=>{ setDietaryVegan(!dietaryVegan); setIsEdited(true) }}/> 
										<Checkbox checked={dietaryLactose} label={dietaryOptions.lactose} id="dietary_lactose" key="lactose" onCheck={()=>{ setDietaryLactose(!dietaryLactose); setIsEdited(true) }}/> 
										<Checkbox checked={dietaryNuts} label={dietaryOptions.nuts} id="dietary_nuts" key="nuts" onCheck={()=>{ setDietaryNuts(!dietaryNuts); setIsEdited(true) }}/> 
										<Checkbox checked={dietaryLowCarb} label={dietaryOptions.lowcarb} id="dietary_lowcarb" key="lowcarb" onCheck={()=>{ setDietaryLowCarb(!dietaryLowCarb); setIsEdited(true) }}/> 

									</Form.Group>
								</Form.Row>
								<Form.Row>
									<FormLabel.Primary as={Col} sm={12}>Mobility Accommodations</FormLabel.Primary>
								</Form.Row>

									<Form.Group as={Row} className="switch-row">
										<Switch isOn={reducedMobility} onColor="#0d9948" handleToggle={() => {setReducedMobility(!reducedMobility); setIsEdited(true);  }} id="reducedMobility" />
										<Form.Label htmlFor="reducedMobility">I have reduced mobility but do not require ramps</Form.Label>
									</Form.Group>

									<Form.Group as={Row} className="switch-row">
										<Switch isOn={adaRequired} onColor="#0d9948" handleToggle={() => {setAdaRequired(!adaRequired); setIsEdited(true);  }} id="adaRequired" />
										<Form.Label htmlFor="adaRequired">I require ramps or other ADA accommodations</Form.Label>
									</Form.Group>

								<Form.Row>
									<Form.Group as={Col}>
										<FormLabel.Primary>Food Allergies</FormLabel.Primary>
										<Form.Control placeholder="" id="foodAllergies" value={foodAllergies} onChange={e => { handleFieldChange(e.target.id, e.target.value); }}/>
									</Form.Group>
								</Form.Row>
								<Form.Row>
									<Form.Group as={Col}>
										<FormLabel.Primary>Medical Allergies</FormLabel.Primary>
										<Form.Control placeholder="" id="medicalAllergies" value={medicalAllergies} onChange={e => { handleFieldChange(e.target.id, e.target.value); }}/>
										<Form.Text id="foodallergieshelp" muted>Are there any medicines you cannot have?</Form.Text>
									</Form.Group>
								</Form.Row>
								<Form.Row>
									<Form.Group as={Col}>
										<FormLabel.Primary>Emergency Care Info</FormLabel.Primary>
										<Form.Control placeholder="" id="emergencyCareInfo" value={emergencyCareInfo} onChange={e => { handleFieldChange(e.target.id, e.target.value); }}/>
										<Form.Text id="medallergieshelp" muted>What do first responders need to know?</Form.Text>
									</Form.Group>
								</Form.Row>
								<Form.Row>
									<Form.Group as={Col}>
										<FormLabel.Primary>Other Notes</FormLabel.Primary>
										<Form.Control placeholder="" id="careNotes" value={careNotes} onChange={e => { handleFieldChange(e.target.id, e.target.value); }}/>
										<Form.Text id="othernoteshelp" muted>Any other concerns or needs you have.</Form.Text>
									</Form.Group>
								</Form.Row>

								</Container>
							</Card.Body>
						</FormCard>
						
					</Col>
				</Row>


				<Row>
					<Col>
						{isSavingToServer ?  
						 <SaveSpinnerContainer>
							<Spinner animation="grow" variant="primary" />&nbsp;
							<Spinner animation="grow" variant="secondary" />&nbsp;
							<Spinner animation="grow" variant="success" />&nbsp;
							<Spinner animation="grow" variant="danger" />&nbsp;
							<Spinner animation="grow" variant="warning" />&nbsp;
							<Spinner animation="grow" variant="info" />
						 </SaveSpinnerContainer>
							:
							<SaveButton disabled={ (isEdited)?false:true } onClick={e => { savePublicProfile(); }}  />
 						}
					</Col>
				</Row>
			</Container>
</>
    );
}
 
export default PrivateInfoEditor;