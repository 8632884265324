import React, { useState, useEffect }  from "react";
import axios from 'axios';
import { useAuth } from "../../contexts/auth";
import { useMyRegistration } from "../../contexts/myregistration.js";
import styled from 'styled-components';

import { LoadingSpinner } from "../../styled-components/AuthForms.js";

import { FormWrapper, FormCard, FormLabel, IconInputLabel, StyledInputGroup,SubmitButton, RegularButton } from "../../styled-components/Forms.js";

// import {
//   useRouteMatch,
// 	useHistory
// } from "react-router-dom";


import Receipt from './ReceiptLine'
import CreditCardForm from './CreditCardForm.js';

import {loadStripe} from '@stripe/stripe-js';
import {Elements} from '@stripe/react-stripe-js';

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import Spinner from 'react-bootstrap/Spinner'
import Alert from 'react-bootstrap/Alert';

import './Payments.css'; 

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);


const ReminderLine = styled(Row)`
	width:100%; 
	border-bottom:1px solid #EEEEEE; 
	position:relative; 
	margin-right:0px; 
	margin-left:0px; 
	padding-top:10px; 
	padding-bottom:10px; 
	padding-left:10px;
	:last-of-type{ 
		border-bottom:none;
	}
	& .late{ 
		font-size:12px; 
		color:#b82a34; 
	}
`; 


function Payments(props) {
	const {myRegistration, 
				 // eslint-disable-next-line
				 setMyRegistration, 
				 refreshRegistration} = useMyRegistration();
  const { authToken, setAuthToken } = useAuth();
//   let history = useHistory();
	const [payments,setPayments] = useState(null); 
	const [schedules,setSchedules] = useState(null); 
	const [amountToPayToday, setAmountToPayToday] = useState('');
	const [showPaymentPanel, setShowPaymentPanel] = useState(false);
	const [showPaymentThankYou, setShowPaymentThankYou] = useState(false);
	const [showMaxAmountError, setShowMaxAmountError] = useState(false);
	const [showMinAmountError, setShowMinAmountError] = useState(false);

	const amountDue = (myRegistration && myRegistration.registrationStatus) ? myRegistration.registrationStatus.guestPrice - myRegistration.registrationStatus.amountPaid : 0; 
//   let match = useRouteMatch();
// 	const [tabKey, setTabKey] = useState((match.path==='/profile/private')?'private':((match.path==='/profile/travel')?'travel':'public'));
																			
// 	const paymentStatusLoading = <div id="LoadingData"><Spinner animation="grow" variant="secondary" /></div>;

// 	const partiallyPaidStatus = <p>You have started making payments for your registration. So far, you have paid 
// 																${myRegistration.registrationStatus.amountPaid} 
// 													toward your ${myRegistration.registrationStatus.guestPrice} total.</p>
// 											}
	
	function getPayments(){ 
	axios.get(process.env.REACT_APP_API_HOST+"/registrations/mine/payments?expand=schedules",{headers: {'Bearer': authToken.token}})
				.then(result => {
								if (result.status === 200) {
									setShowPaymentThankYou(false);
									setShowPaymentPanel(false);
									setAmountToPayToday('');
									setSchedules(null); 
									setPayments(result.data.payments); 
									if(result.data.schedules && result.data.schedules.length>0){ 
										setSchedules(result.data.schedules); 
									} 
									if(result.headers['x-authtoken']) setAuthToken(JSON.parse(result.headers['x-authtoken'])); 
								} else {
								}
							}).catch(e => {
							});	
	} 
	
	// this disables React's strict checking on this one-time effect, as the warning is irrelevant in this case. 
	// eslint-disable-next-line
	useEffect(()=>{ getPayments(); },[]); 
	
	function processTodayAmount(e){ 
		e.preventDefault();
		var amountval = parseFloat(amountToPayToday);
		if(isNaN(amountval)){ setAmountToPayToday(''); return false; }
		if(myRegistration.registrationStatus.status !== 'paid' && amountval>amountDue) amountval = amountDue;
		if(amountval<1){ 
			setShowMinAmountError(true); 
			return false; 
		}if(amountval>50000){ 
			setShowMaxAmountError(true); 
			return false; 
		} else { 
			setShowMaxAmountError(false); 
			setShowMinAmountError(false); 
			setAmountToPayToday(amountval); 
			setShowPaymentPanel(true); 
		} 
	} 
	
	function finishPayment(paymentId){ 
		setShowPaymentThankYou(true); 
		setTimeout(function(){ 
			getPayments(); 
			refreshRegistration(); },5000); 
	} 
	function reminderIsLate(strDate){ 
		var date = new Date(strDate); 
		var today = new Date(); 
		return (date<today);
	} 
	function formatReminderDate(strDate){ 
		var date = new Date(strDate); 
		var months = ["January","February","March","April","May","June","July","August","September","October","November","December"]; 
		var suffix = 'th'; 
		var day = date.getDate(); 
		if(day===1 || day === 21 || day === 31) suffix = 'st'; 
		if(day===2 || day === 22) suffix = 'nd'; 
		if(day===3 || day === 23) suffix = 'rd'; 
		var string = months[date.getMonth()]+' '+date.getDate()+suffix; 
		return string; 

	} 
	function resetPaymentForm(){ 
		setShowPaymentPanel(false); 
		setShowPaymentThankYou(false); 
		setAmountToPayToday(''); 
	} 
	
	const registrationStatusCard = <FormCard id="" className="orange">
										<Card.Header as="h5">Registration Status</Card.Header>
										<Card.Body> 
											{myRegistration.status && myRegistration.registrationStatus.status==='partially-paid' && 
												<p>You have started making payments for your registration. So far, you have paid ${myRegistration.registrationStatus.amountPaid} toward
													 your ${myRegistration.registrationStatus.guestPrice} total.</p>
											}
											{myRegistration.status && myRegistration.registrationStatus.status==='paid' && 
												<p>Your registration is paid in full!</p>
												}
										</Card.Body>
									</FormCard>; 
	
	if((!schedules && !payments) || !myRegistration.registrationStatus) return(<Container>
							<h1>Payments</h1>
							<LoadingSpinner>
							<Spinner animation="grow" variant="primary" />&nbsp;
							<Spinner animation="grow" variant="secondary" />&nbsp;
							<Spinner animation="grow" variant="success" />&nbsp;
							<Spinner animation="grow" variant="danger" />&nbsp;
							<Spinner animation="grow" variant="warning" />
						</LoadingSpinner>
						</Container>); 
	
	return (<>
						<Container>
							<h1>Payments</h1>
			 { schedules && 
							<Row>
								<Col>
									{registrationStatusCard}
								</Col>
							</Row>
				 }
							<Row>
								{schedules && 
								<Col xs={{ span: 12, order: 12 }} md={{ span: 12, order: 12 }} lg={{ span: 3, order: 1 }}>
									<FormCard id="" className="dark-blue">
										<Card.Header as="h5">Upcoming Reminders</Card.Header>
											{schedules && schedules.map(p=><ReminderLine key={p.id}>${p.amount - p.amountPaid} on {formatReminderDate(p.scheduledDate)} 
																										{ reminderIsLate(p.scheduledDate) && <div class="late">Missed - Please pay ASAP</div> }
																									</ReminderLine>)}
									</FormCard>
								</Col>
								}
								<Col xs={{ span: 12, order: 1 }} md={{ span: 12, order: 1 }} lg={{ span: ((schedules)?4:6), order: 2 }}>
									
								{!schedules && registrationStatusCard}
									
									<FormCard id="" className="green">
										<Card.Header as="h5">Make a { (myRegistration.registrationStatus.status==='paid') ? 'Donation':'Payment' }</Card.Header>
										<Card.Body>
											{ !showPaymentPanel && !showPaymentThankYou &&
											<FormWrapper onSubmit={processTodayAmount}>
													{ (myRegistration.registrationStatus.status==='paid') && <p>Puppies in the Mountains is a nonprofit organization. Any donations above your registration fee is tax deductible in the US.</p> }
												<Form.Row>
													<Form.Group as={Col}>
														<FormLabel.Primary>How much would you like to { (myRegistration.registrationStatus.status==='paid') ? 'donate':'pay today' }?</FormLabel.Primary>
														<StyledInputGroup>
															<InputGroup.Prepend>
																<IconInputLabel id="basic-addon7">$</IconInputLabel>
															</InputGroup.Prepend>
															<Form.Control id="paytoday" className="" value={amountToPayToday} onChange={(e)=>{ setAmountToPayToday(e.target.value); }} placeholder="" />
														</StyledInputGroup>
													</Form.Group>
												</Form.Row>												
												{showMinAmountError && <Row><Col><Alert variant="danger">Please enter at least $1.</Alert></Col></Row> }
												{showMaxAmountError && <Row><Col><Alert variant="danger">Thanks for your generousity, but please limit your donations to $50,000 at a time.</Alert></Col></Row> }

												<SubmitButton value="Next" className="right"/>
											</FormWrapper>
											}
											{ showPaymentPanel && !showPaymentThankYou && 
													<Elements stripe={stripePromise}>
														<CreditCardForm amount={amountToPayToday} onSuccess={finishPayment} paymentType={(myRegistration.registrationStatus.status==='paid')?'donation':'registration'} />
													</Elements>
											} 
											{ showPaymentThankYou && <>
												<p>Thank you for making a { (myRegistration.registrationStatus.status==='paid') ? 'donation':'payment' }! We will email you a receipt shortly. It may take a few minutes for your payment history { schedules && <>and scheduled reminders</>} to update. </p>
												<RegularButton onClick={resetPaymentForm}>Make Another Payment</RegularButton>
												</>
											}
											
										</Card.Body>
									</FormCard>
								</Col>
								<Col xs={{ span: 12, order: 2 }} md={{ span: 12, order: 2 }} lg={{ span: ((schedules)?5:6), order: 3 }}>
									<FormCard id="" className="purple">
										<Card.Header as="h5">Payment History</Card.Header>
											{payments && payments.map(p=><Receipt receipt={p} key={p.id} />)}
									</FormCard>
								</Col>
							</Row>
						 </Container>		
					</>
	);
}
 
export default Payments;