import React, {useState, useEffect} from "react";
import axios from 'axios';
import { useMyRegistration } from "../../contexts/myregistration.js";
import { useEventSettings } from "../../contexts/eventsettings";

import { FormCard, FormLabel, SaveButton, SaveSpinnerContainer } from "../../styled-components/Forms.js";

import Switch from '../../custom-widgets/Switch.js'; 

import Form from 'react-bootstrap/Form'
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Spinner from 'react-bootstrap/Spinner'

import '../../custom-widgets/Switch.css'; 

function PublicInfoEditor(props) {
	const { myRegistration, setMyRegistration } = useMyRegistration(); 
  const { eventSettings } = useEventSettings();
	const [isSavingToServer, setIsSavingToServer] = useState(false);
	const [isEdited, setIsEdited] = useState(false);
	const [eventStartDate] = useState('Oct 1, 2021'); // #TODO: These should be autocalculated when eventSettings is not hard coded. 
	
	const [arrivalType, setArrivalType] = useState('');
	const [airport, setAirport] = useState('');
	const [airline, setAirline] = useState('');
	const [arrivalTime, setArrivalTime] = useState('');
	const [flightNumber, setFlightNumber] = useState('');
	const [drivingArriveBefore6, setDrivingArriveBefore6] = useState('');
	const [shuttle, setShuttle] = useState(false);
	const [arrivalTypeOptions, setArrivalTypeOptions] = useState({});
	const [airportOptions, setAirportOptions] = useState({});
	const [airlineOptions, setAirlineOptions] = useState({});
	
	useEffect(() => {
	axios.get(process.env.REACT_APP_API_HOST+"/events/"+eventSettings.key+"/fieldoptions")
				.then(result => {
								if (result.status === 200) {
									setArrivalTypeOptions(result.data["travel.arrivaltype"]); 
									setAirlineOptions(result.data["travel.airline"]); 
									setAirportOptions(result.data["travel.airport"]); 
								} else {
								}
							}).catch(e => {
							});
	},[eventSettings.key]); 
	
	function handleFieldChange(id,val){ 
		if(id==='arrivalType'){
			setArrivalType(val); 
			if(val==='flying'){
				setArrivalTime(''); 
				setDrivingArriveBefore6(null); 
			} 
		} 
		
		if(id==='airport') setAirport(val); 
		if(id==='airline') setAirline(val); 
		if(id==='arrivalTime') setArrivalTime(val); 
		if(id==='flightNumber') setFlightNumber(val); 
		if(id==='shuttle') setShuttle(val); 
		if(id==='drivingArriveBefore6') setDrivingArriveBefore6(val); 
		setIsEdited(true); 
	} 
	function savePublicProfile(){ 
		
		myRegistration.travel.arrivalType = (!arrivalType)?null:arrivalType; 
		
		if(arrivalType==='flying'){ 
			myRegistration.travel.useShuttle = shuttle; 
			myRegistration.travel.arrivalTime = (!arrivalTime || arrivalTime==='Before 6pm' || arrivalTime==='After 6pm')?'':arrivalTime; 
			myRegistration.travel.airport = (!airport)?'':airport; 
			myRegistration.travel.airline = (!airline)?'':airline; 
			myRegistration.travel.flight = (!flightNumber)?'':flightNumber; 
		} else { 
			myRegistration.travel.arrivalTime = (drivingArriveBefore6==='yes')?'Before 6pm':'After 6pm'; 
			myRegistration.travel.useShuttle = false;
			myRegistration.travel.airport = '';
			myRegistration.travel.airline = '';
			myRegistration.travel.flight = ''; 
		} 
		
		setIsSavingToServer(true); 
		setMyRegistration(myRegistration); 
	} 
	
  useEffect(() => {
		if(myRegistration.travel){
			setArrivalType(myRegistration.travel.arrivalType);
			setArrivalTime(myRegistration.travel.arrivalTime);
			if(myRegistration.travel.arrivalType==='flying'){ 
				setShuttle(myRegistration.travel.useShuttle);
				setAirline(myRegistration.travel.airline);
				setAirport(myRegistration.travel.airport);
				setFlightNumber(myRegistration.travel.flight);
			}	else { 		
				setDrivingArriveBefore6((myRegistration.travel.arrivalTime==='Before 6pm')?'yes':'no');
			}
		}
		if(myRegistration.status==='ok'){ setIsSavingToServer(false); setIsEdited(false); }
  }, [myRegistration]) 
			 
    return (<>
			<Container className="form-cards">
				<Row>
					<Col xs={12} lg={(arrivalType==='flying')?6:12} className={(arrivalType==='flying')?"":"narrow-body"}>
						<FormCard id="ProfilePhotos" className="gold">
							<Card.Header as="h5">Travel Information</Card.Header>
								<Card.Body>
								<p>PITM is held at a private retreat and convention center in the forests outside Colorado Springs, CO. 
									The address will be sent to guests the week of the event (between Sept 26–30, 2021).</p> 
								<p>Check-in will be open from 2pm to 6pm on {eventStartDate}. Late arrivals will be sent a text message with instructions on how 
									to find the Pack and get checked in. Because the venue may have other guests departing while we are arriving, street clothes 
									are required until the opening Mosh.</p>
								</Card.Body>
						</FormCard>
						
						<FormCard id="" className="green">
							<Card.Header as="h5">My Arrival</Card.Header>
							<Card.Body>
								<Form.Row>
									<Form.Group as={Col} >
										<FormLabel.Primary>How do you plan on arriving at PITM?</FormLabel.Primary>
										<Form.Control id="arrivalType" as="select" value={arrivalType} onChange={e => { handleFieldChange(e.target.id, e.target.value); }} >
												<option></option>
												{
													Object.keys(arrivalTypeOptions).map((value) => <option key={value} value={value}>{arrivalTypeOptions[value]}</option> )
												}
										</Form.Control>
									</Form.Group>
								</Form.Row>
								{ (arrivalType==='driving') &&
								<Form.Row>
									<Form.Group as={Col} >
										<FormLabel.Primary>Will you arrive at the venue before 6pm?</FormLabel.Primary>
										<Form.Control id="drivingArriveBefore6" as="select" value={drivingArriveBefore6} onChange={e => { handleFieldChange(e.target.id, e.target.value); }} >
												<option></option>
												<option value="yes">Yes, I will arrive between 2pm and 6pm</option>
												<option value="no">No, I will probably arrive after 6pm</option>
										</Form.Control>
									</Form.Group>
								</Form.Row>
									
									}
							</Card.Body>
						</FormCard>

					</Col>

					{ (arrivalType==='flying') &&
					<Col xs={12} lg={6}>
						
						<FormCard id="" className="dark-blue">
							<Card.Header as="h5">Flight Information</Card.Header>
							<Card.Body>
								<Form.Row>
									<Form.Group as={Col} >
										<FormLabel.Primary>Arriving at Airport</FormLabel.Primary>
										<Form.Control id="airport" as="select" value={airport} onChange={e => { handleFieldChange(e.target.id, e.target.value); }} >
												<option></option>
												{
													Object.keys(airportOptions).map((value) => <option key={value} value={value}>{airportOptions[value]}</option> )
												}
										</Form.Control>
									</Form.Group>
								</Form.Row>
								<Form.Row>
									<Form.Group as={Col} >
										<FormLabel.Primary>Airline</FormLabel.Primary>
										<Form.Control id="airline" as="select" value={airline} onChange={e => { handleFieldChange(e.target.id, e.target.value); }} >
												<option></option>
												{
													Object.keys(airlineOptions).map((value) => <option key={value} value={value}>{airlineOptions[value]}</option> )
												}
										</Form.Control>
									</Form.Group>
								</Form.Row>
								<Form.Row>
									<Form.Group as={Col} >
										<FormLabel.Primary>Flight Number</FormLabel.Primary>
										<Form.Control placeholder="" id="flightNumber" value={flightNumber} onChange={e => { handleFieldChange(e.target.id, e.target.value); }}/>
									</Form.Group>
								</Form.Row>
								<Form.Row>
									<Form.Group as={Col} >
										<FormLabel.Primary>Arrival Time</FormLabel.Primary>
										<Form.Control placeholder="" id="arrivalTime" value={arrivalTime} onChange={e => { handleFieldChange(e.target.id, e.target.value); }}/>
										<Form.Text id="arrivaltimehelp" muted>If arriving on day <i>other</i> than October 1st, please include that date.</Form.Text>
									</Form.Group>
								</Form.Row>
								{ (airport==='DEN') && 
								<Form.Row>
									<Form.Group as={Col}>
										<Switch isOn={shuttle} onColor="#0d9948" handleToggle={() => {setShuttle(!shuttle); setIsEdited(true);  }} id="shuttle" />
										<Form.Label htmlFor="shuttle">
											I'd like to use the PITM Airport Shuttle.
											<Form.Text id="shuttlehelp" muted>You must land at Denver International <i>on October 1st</i> by 1:30pm to use the shuttle.</Form.Text>
										</Form.Label>
									</Form.Group>
								</Form.Row>
									}
								{ (airport==='COS') && 
									<Form.Row>
										<Col>
											<p><b>Important:</b> There is no PITM shuttle from Colorado Springs Airport. Please plan to rent a car, use Uber or Lyft, or get a ride from a friend.</p>
										</Col>
									</Form.Row>
									}
							</Card.Body>
						</FormCard>

					</Col>
						}
				</Row>


				<Row>
					<Col className={(arrivalType==='flying')?"":"narrow-body"}>
						{isSavingToServer ?  
						 <SaveSpinnerContainer>
							<Spinner animation="grow" variant="primary" />&nbsp;
							<Spinner animation="grow" variant="secondary" />&nbsp;
							<Spinner animation="grow" variant="success" />&nbsp;
							<Spinner animation="grow" variant="danger" />&nbsp;
							<Spinner animation="grow" variant="warning" />&nbsp;
							<Spinner animation="grow" variant="info" />
						 </SaveSpinnerContainer>
							:
							<SaveButton disabled={ (isEdited)?false:true } onClick={e => { savePublicProfile(); }}  />
 						}
					</Col>
				</Row>
			</Container>
</>
    );
}
 
export default PublicInfoEditor;